import { parseConfig } from './json-config';

const config = parseConfig();
export const MIN_FREE_DELIVERY_SUM = 8000;
export const PAID_DELIVERY_COST = 200;

export const DADATA_TOKEN = '0eab170bd38283f6ecf1bdec24fe57df6894b870';

export const TYPES_DELIVERY = {
  courier : ['cse_courier'],
  pickup  : ['cse_optic_pickup', 'cse_pickup']
};

export const TYPE_DIRECTORY = {
  consumer: {
    label : 'потребитель',
    value : 'consumer'
  },
  specialist: {
    label : 'специалист',
    value : 'specialist'
  }
};

export const ID_FORM_PASSWORD_CHANGE = 'password-change';

export const sendEventYM = (target, params = {}) => {
  // target - цель, обязательный параметр
  // params - параметры для цели, необязательный параметр
  // event - тип события, необязательный параметр, по умолчанию reachGoal
  if ('ym' in window && config && config.idYM) {
    window.ym(config.idYM, 'reachGoal', target, params);
  }
};

export const sendParamsYM = (params = {}) => {
  // params - параметры визита
  if ('ym' in window && config && config.idYM) {
    window.ym(config.idYM, 'params', { params });
  }
};

export const sendUserIdYM = (userId) => {
  if ('ym' in window && config && config.idYM) {
    window.ym(config.idYM, 'setUserID', userId);
  }
};

export const REGEXP_EMAIL = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const URL_RECAPTCHA = 'https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit&hl=ru';

export const PLACEHOLDER_COUNTRY = {
  ru : '(000) 000-00-00',
  by : '(00) 000-00-00',
  am : '(00) 000-00-00',
  kz : '(000) 000-00-00',
  kg : '(000) 000-00-00'
};

export const MASK_INPUT_COUNTRY = {
  ru : ['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/],
  by : ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/],
  am : ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/],
  kz : ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/],
  kg : ['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/]
};

export const PATTERN_INPUT_COUNTRY = {
  ru : ['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/],
  by : ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/],
  am : ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/],
  kz : ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/],
  kg : ['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/]
};

export const hasProperty = (entries, key) => Object.prototype.hasOwnProperty.call(entries, key);

export const getUnmaskedPhone = (phone) => (phone ? phone.replace(/[^0-9]+/g, '') : phone);

export const getTypeDevice = () => {
  const currWidth = window.innerWidth;

  switch (true) {
    case currWidth <= 767:
      return 'mobile';
    case currWidth > 767 && currWidth <= 1023:
      return 'tablet';
    default:
      return 'desktop';
  }
};

export const compare = (a, b) => {
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
};

export const getWordDeclension = (texts, count) => {
  const n = Math.abs(count) % 100;
  const n1 = n % 10;

  if (n > 10 && n < 20) {
    return texts[2];
  }

  if (n1 > 1 && n1 < 5) {
    return texts[1];
  }

  if (n1 === 1) {
    return texts[0];
  }

  return texts[2];
};

export const groupingArrayElements = (list, groupingParam) => {
  const keys = [];

  const map = list.reduce((r, item) => {
    const res = r;

    // Сохраняем исходный порядок ключей
    keys.push(item[groupingParam]);

    res[item[groupingParam]] = res[item[groupingParam]] || [];
    res[item[groupingParam]].push(item);
    return res;
  }, {});

  const result = [];
  // Выбираем только уникальные ключи
  const uniqueKeys = keys.filter((el, ind) => ind === keys.indexOf(el));

  uniqueKeys.forEach((key) => {
    result.push(map[key]);
  });

  return result;
};

// eslint-disable-next-line
export const emailRegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const isIosMobile = (/(ipad|iphone|iPod)/i.test(navigator.userAgent))
  || navigator.platform === 'iPad'
  || navigator.platform === 'iPhone'
  || navigator.platform === 'iPod'
  || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

export const isTouchDevice = (/(ipad|iphone|android|mobile|touch)/i.test(navigator.userAgent))
  || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

export const listAddressTypes = [
  {
    label : 'Пункт выдачи',
    value : 'point'
  },
  {
    label : 'Курьер',
    value : 'courier'
  }
];

export const setCookie = (name, value, options = {}) => {
  const currOptions = { path: '/', ...options };

  if (currOptions.expires instanceof Date) {
    currOptions.expires = currOptions.expires.toUTCString();
  }

  let updatedCookie = `${encodeURIComponent(name)}=${value}`;

  Object.keys(currOptions).forEach((optionKey) => {
    updatedCookie += `; ${optionKey}`;

    const optionValue = currOptions[optionKey];
    if (optionValue !== true) {
      updatedCookie += `=${optionValue}`;
    }
  });

  document.cookie = updatedCookie;
};

export const getCookie = (name) => {
  // eslint-disable-next-line
  const matches = document.cookie.match(new RegExp(`(?:^|; )${name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1')}=([^;]*)`));
  return matches ? JSON.parse(decodeURIComponent(matches[1])) : null;
};

export const deleteCookie = (name) => {
  setCookie(name, '', {
    'max-age': -1
  });
};

export const initVideoApi = () => {
  const __YOUTUBE_IFRAME_API_SCRIPT_TAG_ID = 'youtube-iframe-api-script-tag';

  const isScriptLoaded = document.querySelector(`script#${__YOUTUBE_IFRAME_API_SCRIPT_TAG_ID}`);

  if (isScriptLoaded) {
    return;
  }

  const tag = document.createElement('script');

  tag.id = __YOUTUBE_IFRAME_API_SCRIPT_TAG_ID;
  tag.src = 'https://www.youtube.com/iframe_api';

  const firstScriptTag = document.getElementsByTagName('script')[0];
  firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
};

export const initVKVideoApi = () => {
  const __VK_IFRAME_API_SCRIPT_TAG_ID = 'vk-iframe-api-script-tag';

  const isScriptLoaded = document.querySelector(`script#${__VK_IFRAME_API_SCRIPT_TAG_ID}`);

  if (isScriptLoaded) {
    return;
  }

  const tag = document.createElement('script');

  tag.id = __VK_IFRAME_API_SCRIPT_TAG_ID;
  tag.src = 'https://vk.com/js/api/videoplayer.js';

  const firstScriptTag = document.getElementsByTagName('script')[0];
  firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
};
