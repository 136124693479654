import { setConfig } from '../../utils/json-config';
import { REGEXP_EMAIL } from './common';

const getServerErrors422 = (response) => {
  const resultErrors = {};
  const errorsList = response.data;

  Object.keys(errorsList).forEach((field) => {
    resultErrors[field] = '';

    Object.values(errorsList[field]).forEach((err) => {
      if (resultErrors[field]) {
        resultErrors[field] += `, ${err}`;
      } else {
        resultErrors[field] = err;
      }
    });
  });

  return { errors: resultErrors };
};

export const getServerErrors = (response) => {
  let result = null;

  if (response.status === 422) {
    result = getServerErrors422(response);
  } else if (response.errors && response.errors.length) {
    const codes = response.errors.reduce((acc, x) => [...acc, x.code], []);

    if (codes.includes(5011) && response.data && response.data.token) {
      // Нужно заменить token на странице
      setConfig({
        sessid: response.data.token
      });

      result = { token: response.data.token };
    } else if (codes.includes(8)) {
      result = { errors: 'Вы превысили максимальное количество отправленных SMS' };
    } else if (response.errors.find((err) => err.message === 'wrong_code')) {
      result = { errors: 'Неправильно введен код' };
    } else {
      let resultErrors = '';

      response.errors.forEach((error) => {
        if (resultErrors) {
          resultErrors += `, ${error.message}`;
        } else {
          resultErrors = error.message;
        }
      });

      result = { errors: resultErrors };
    }
  }

  return result;
};

const clearErrors = (errors) => {
  const _errors = { ...errors };

  Object.keys(_errors).forEach((key) => {
    _errors[key].triggered = false;
  });

  return _errors;
};

const hasProperty = (obj, prop) => Object.prototype.hasOwnProperty.call(obj, prop);

const getValidElementWithChild = (category, listSelectedElements, params, nameField) => {
  let valid = true;
  // Если в списке параметров есть элемент nameField и у него есть options,
  // то находим в списке options выбранную категорию
  const currObj = params && nameField && params[nameField] && params[nameField].options && params[nameField].options.length
    ? params[nameField].options.find((item) => item.value === category)
    : null;

  // Если у текущей категории есть подкатегории, то проверяем, что выбрана хотя бы 1 подкатегория
  if (currObj && currObj.children && currObj.children.length) {
    // Находим пересечение массивов: список выбранных элементов listSelectedElements и список подкатегорий для текущей категории
    const intersection = listSelectedElements.filter((x) => currObj.children.find(((child) => child.value === x)));
    // Если пересечение пустое - значит текущая категория не валидна
    if (!intersection || !intersection.length) {
      valid = false;
    }
  }

  return valid;
};

// eslint-disable-next-line max-statements
const getValidRules = (rule, value, rules, nameError, _errors, params) => {
  let valid = true;
  const errors = { ..._errors };

  // Обязательные поля
  if (rule === 'required' && (!value || (value && Array.isArray(value) && !value.length))) {
    errors[nameError].triggered = true;
    errors[nameError].message = rules[rule];
    valid = false;
  }

  // Обязательные поля с кастомным правилом
  if (rule === 'requiredCustom' && rules[rule] && typeof rules[rule].errorsFunc === 'function' && rules[rule].errorsFunc(value)) {
    errors[nameError].triggered = true;
    errors[nameError].message = rules[rule].message;
    valid = false;
  }

  // Поле email
  if (rule === 'email' && value && (!REGEXP_EMAIL.test(value) || value.indexOf('@email.com') > -1)) {
    errors[nameError].triggered = true;
    errors[nameError].message = rules[rule];
    valid = false;
  }

  // Поле телефон
  if (rule === 'phoneRu' && value && value.length !== 10) {
    errors[nameError].triggered = true;
    errors[nameError].message = rules[rule];
    valid = false;
  }

  // Минимальное количество символов
  if (rule === 'minLength' && value && value.length < rules[rule].value) {
    errors[nameError].triggered = true;
    errors[nameError].message = rules[rule].message(rules[rule].value);
    valid = false;
  }

  // Максимально количество символов
  if (rule === 'maxLength' && value && value.length > rules[rule].value) {
    errors[nameError].triggered = true;
    errors[nameError].message = rules[rule].message(rules[rule].value);
    valid = false;
  }

  // Допустимый диапазон значений
  if (rule === 'range' && value) {
    const resultVal = value ? value.replace(',', '.') : value;
    if (parseFloat(resultVal) > parseFloat(rules[rule].max) || parseFloat(resultVal) < parseFloat(rules[rule].min)) {
      errors[nameError].triggered = true;
      errors[nameError].message = rules[rule].message(rules[rule].min, rules[rule].max);
      valid = false;
    }
  }

  // Значение со знаком
  if (rule === 'withSign' && value && `${value}` !== '0' && value[0] !== '+' && value[0] !== '-') {
    errors[nameError].triggered = true;
    errors[nameError].message = rules[rule].message;
    valid = false;
  }

  // Проверка на соответствие
  if (rule === 'isEqual' && value && value !== rules[rule].value) {
    errors[nameError].triggered = true;
    errors[nameError].message = rules[rule].message;
    valid = false;
  }

  // Если у категории есть подкатегории, то должна быть выбрана хотя бы 1 подкатегория
  if (rule === 'requiredChild' && value) {
    value.forEach((el) => {
      const isValid = getValidElementWithChild(el, value, params, nameError);
      if (!isValid) {
        errors[nameError].triggered = true;
        errors[nameError].message = rules[rule];
        valid = false;
      }
    });
  }

  return { valid, errors };
};

export function getValidate(fields, errors, serverErrors, params = {}) {
  if (!fields || !errors) return null;

  let valid = true;
  let _errors = clearErrors(errors);

  if (serverErrors && typeof serverErrors === 'object') {
    Object.keys(serverErrors).forEach((name) => {
      if (hasProperty(_errors, name)) {
        _errors[name].message = serverErrors[name];
        _errors[name].triggered = true;
        valid = false;
      }
    });
  }

  // Перебираем все ошибки
  Object.keys(_errors).forEach((nameError) => {
    const { rules } = _errors[nameError];

    // Если у ошибки есть правила обработки - выводим сообщение
    if (rules) {
      Object.keys(rules).forEach((rule) => {
        // Определяем значение поля
        let value = null;
        if (hasProperty(fields, nameError) && fields[nameError]) {
          value = typeof fields[nameError] === 'string' ? fields[nameError].trim() : fields[nameError];
        }

        // Валидация в зависимости от правил
        const validRules = getValidRules(rule, value, rules, nameError, _errors, params);
        if (!validRules.valid) {
          valid = false;
        }

        _errors = {
          ..._errors,
          ...validRules.errors
        };
      });
    }
  });

  return {
    valid,
    errors: _errors
  };
}

export const getTextServerErrors = (serverErrors) => {
  if (serverErrors && typeof serverErrors === 'string') {
    return serverErrors;
  }

  if (serverErrors && typeof serverErrors === 'object'
    && Object.keys(serverErrors)
    && Object.keys(serverErrors).length
  ) {
    let resultMessage = '';

    Object.keys(serverErrors).forEach((item) => {
      if (resultMessage) {
        resultMessage += ', ';
      }

      resultMessage += `${item} - ${serverErrors[item]}`;
    });

    return resultMessage;
  }

  return null;
};
